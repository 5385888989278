<template>
  <div class="meta-investment">
    <header-slot />
    <card-creative-report :send-year="sendYear" :send-month="sendMonth" />
  </div>
</template>
<script>
import CardCreativeReport from "@/views/ceo/dashboard/components/CardCreativeReport.vue";
import moment from "moment";
export default {
  name: "MetaInvestmentMain",
  components: {
    CardCreativeReport,
  },
  data() {
    return {
      sendYear: null,
      sendMonth: null,
    };
  },
  computed: {},
  created() {
    this.sendYear = moment().year();
    this.sendMonth = moment().month() + 1;
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
</style>